<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline" class="text-white"></nb-icon>
        </a>
        <a class="logo" *ngIf="!isNewUU" href="#" (click)="navigateHome()">amudar.io portal</a>
        <a class="logo" *ngIf="isNewUU" href="#" (click)="navigateHome()">Yangi O’zbekiston Universiteti
            Meteo-Kuzatuvlar Portali</a>
    </div>
    <!--  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">-->
    <!--    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>-->
    <!--  </nb-select>-->
</div>

<div class="header-container">
    <nb-actions size="small">
        <nb-action class="control-item" (click)="this.rightMenuClicked.emit();">
            <nb-icon class="text-white" icon="radio-outline"></nb-icon>
        </nb-action>
        <nb-action>
            <button nbButton ghost
                    [nbContextMenu]="languages"
                    nbContextMenuTrigger="click"
                    nbContextMenuTag="language-switcher">
                <nb-icon icon="globe-outline" class="text-white"></nb-icon>
            </button>
        </nb-action>
        <nb-action class="user-action">
            <nb-user [nbContextMenu]="userMenu"
                     [onlyPicture]="userPictureOnly"
                     [name]="user?.name"
                     [color]="'white'"
                     size="small"
                     class="text-white"
                     style="color: white"
                     picture="assets/images/user.png">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>
