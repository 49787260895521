import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  constructor() {}

  public getDashboardToken(): string {
    return localStorage.getItem("dashboardToken");
  }

  public setDashboardToken(token: string) {
    localStorage.setItem("dashboardToken", token);
  }
}
