import { Injectable } from "@angular/core";
import { GlobalStore } from "./global.store";
import { HttpService } from "../../@core/backend/common/api/http.service";
import { ApiResponse } from "../../@core/backend/common/api/apiResponse";
import { License } from "../../@core/interfaces/common/license";

@Injectable({ providedIn: "root" })
export class GlobalService {
  constructor(private globalStore: GlobalStore, private http: HttpService) {}

  public updateIsAdmin(isAdmin: boolean): void {
    this.globalStore.update({ isAdmin: isAdmin });
  }

  public async getLicences(orgID: number): Promise<License[]> {
    const licences: ApiResponse<any> = await this.http
      .get(`orgs/${orgID}/licenses`)
      .toPromise();
    return licences.data;
  }
}
