import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";

export interface GlobalState {
  dashboardToken: string;
  isAdmin: boolean;
  loading: boolean;
  isShared: boolean;
  isComparison: boolean;
  isSidebarToggled: boolean;
}

export function createInitialState(): GlobalState {
  return {
    dashboardToken: "",
    isAdmin: false,
    loading: true,
    isShared: false,
    isComparison: false,
    isSidebarToggled: false,
  };
}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "global" })
export class GlobalStore extends Store<GlobalState> {
  constructor() {
    super(createInitialState());
  }
}
