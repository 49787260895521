export const environment = {
  production: true,
  apiUrl: "//oxus.amudar.io/api",
  oxusBotsUrl: "https://oxus-bots.amudar.io",
  forecastApiUrl: "https://api.openweathermap.org/data/2.5/onecall?",
  forecast: {
    // apiKey: "a6a4a220fb3f31e2e94af120cfb21835",
    apiKey: "53d98d29642ba12c4beeb02c4f566d59",
    cnt: 16,
  },
  token: "2|sM3rSXoANgRGGJsUtdIArecMkdd8UlNZRzfhrGTM",
  locale: "uz",
  baseUrl: "https://oxus.amudar.io",
  getMeteoblueWidgetURL(lat: number, long: number) {
    return `https://www.meteoblue.com/ru/%D0%BF%D0%BE%D0%B3%D0%BE%D0%B4%D0%B0/widget/three/${lat}N${long}E490_Asia%2FTashkent?geoloc=fixed&nocurrent=1&noforecast=0&days=7&tempunit=CELSIUS&windunit=METER_PER_SECOND&layout=image`;
  },
  buildForecastUrl(lat, lon): string {
    return `${this.forecastApiUrl}lat=${lat}&lon=${lon}&units=metric&exclude=minutely,hourly,current&appid=${this.forecast.apiKey}`;
  },
};
