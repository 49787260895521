import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { MeteostationData } from "../../../shared/meteostations/models/meteostationData";
import { MeteoforecastData } from "../../../shared/meteostations/models/meteoforecastData";
import { MeteostationParameters } from "../../../shared/meteostations/meteostation-parameters";
import { Device } from "../../../@core/interfaces/common/device";
import { Settings } from "../../../pages/meteostations/dashboard/header/dto/settings";
import {
  AllMeteoData,
  AllMeteoDataForGraphs,
} from "../../../shared/meteostations/models/allMeteoData";
import produce from "immer";

export interface MeteostationsState {
  parameters: MeteostationParameters;
  headerSettings: Settings;
  currentMeteostation: Device;
  meteostations: Device[];
  meteostationsData: MeteostationData[];
  meteoforecastData: MeteoforecastData[];
  allMeteoData: AllMeteoData[];
  allMeteoDataForGraphs: AllMeteoDataForGraphs[];
  hasSpecialParameters: boolean;
  weeklyData: MeteostationData[];
}

export function createInitialState(): MeteostationsState {
  return {
    parameters: null,
    headerSettings: null,
    currentMeteostation: null,
    meteostations: [],
    meteostationsData: [],
    meteoforecastData: [],
    allMeteoData: [],
    allMeteoDataForGraphs: [],
    hasSpecialParameters: false,
    weeklyData: [],
  };
}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "meteostations", resettable: true, producerFn: produce })
export class MeteostationsStore extends Store<MeteostationsState> {
  constructor() {
    super(createInitialState());
  }
}
