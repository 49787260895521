import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Injectable()
export class PublicInterceptor implements HttpInterceptor {
  constructor(private activatedRoute: ActivatedRoute) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams.hasOwnProperty("shared") && +queryParams["shared"]) {
      const token = this.activatedRoute.snapshot.fragment;
      const modifiedRequest = request.clone({
        headers: request.headers.set("Authorization", `Bearer ${token}`),
      });
      return next.handle(modifiedRequest);
    } else {
      return next.handle(request);
    }
  }
}
