import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AnalyticsService } from "./@core/utils";
import { GlobalLoaderService } from "./@theme/services/global-loader.service";
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalStore } from "./store/global";
import { NbIconLibraries } from "@nebular/theme";
import * as Highcharts from "highcharts";
import * as HighchartsStock from "highcharts/highstock";
import Exporting from "highcharts/modules/exporting";
import BoostModule from "highcharts/modules/boost";
BoostModule(Highcharts);
BoostModule(HighchartsStock);
Exporting(Highcharts);
Exporting(HighchartsStock);

@Component({
  selector: "ngx-app",
  template: "<router-outlet></router-outlet>",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public loading = false;
  public Highcharts = Highcharts;
  public HighchartsStock = HighchartsStock;
  private lang = {
    downloadCSV: $localize`Download CSV`,
    downloadJPEG: $localize`Download JPEG image`,
    downloadPDF: $localize`Download PDF document`,
    downloadPNG: $localize`Download PNG image`,
    downloadSVG: $localize`Download SVG vector image`,
    downloadXLS: $localize`Download XLS`,
    exitFullscreen: $localize`Exit from full screen`,
    hideData: $localize`Hide data table`,
    loading: $localize`Loading...`,
    noData: $localize`No data to display`,
    printChart: $localize`Print chart`,
    resetZoom: $localize`Reset zoom`,
    viewData: $localize`View data table`,
    viewFullscreen: $localize`View in full screen`,
    months: [
      $localize`January`,
      $localize`February`,
      $localize`March`,
      $localize`April`,
      $localize`May`,
      $localize`June`,
      $localize`July`,
      $localize`August`,
      $localize`September`,
      $localize`October`,
      $localize`November`,
      $localize`December`,
    ],
    shortMonths: [
      $localize`Jan`,
      $localize`Feb`,
      $localize`Mar`,
      $localize`Apr`,
      $localize`May`,
      $localize`Jun`,
      $localize`Jul`,
      $localize`Aug`,
      $localize`Sep`,
      $localize`Oct`,
      $localize`Nov`,
      $localize`Dec`,
    ],
    weekdays: [
      $localize`Sunday`,
      $localize`Monday`,
      $localize`Tuesday`,
      $localize`Wednesday`,
      $localize`Thursday`,
      $localize`Friday`,
      $localize`Saturday`,
    ],
    rangeSelectorZoom: $localize`Zoom`,
  };

  constructor(
    //   loaderService is used in template don't remove
    public loaderService: GlobalLoaderService,
    private analytics: AnalyticsService,
    private globalStore: GlobalStore,
    private route: ActivatedRoute,
    private router: Router,
    private nbIconLibraries: NbIconLibraries
  ) {
    this.nbIconLibraries.registerFontPack("fas", {
      packClass: "fas",
      iconClassPrefix: "fa",
    });
    this.nbIconLibraries.registerFontPack("far", {
      packClass: "far",
      iconClassPrefix: "fa",
    });
    this.nbIconLibraries.registerFontPack("fab", {
      packClass: "fab",
      iconClassPrefix: "fa",
    });
    this.nbIconLibraries.setDefaultPack("fas");
    this.route.queryParams.subscribe((queryParams) => {
      const isShared = +queryParams["shared"] === 1;
      const isComparison = router.url.includes("comparison");
      globalStore.update({ isShared, isComparison });
    });
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();

    this.Highcharts.setOptions({
      lang: this.lang,
    });
    this.HighchartsStock.setOptions({
      lang: this.lang,
    });
  }
}
