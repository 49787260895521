<h1 id="title" class="title" i18n>Login</h1>
<p class="sub-title" i18n>Hello! Log in with your email.</p>

<!--  <p class="alert-title"><b i18n>Oh snap!</b></p>-->
<div *ngIf="showMessages.error && errors?.length && !submitted">
  <nb-alert
          *ngFor="let error of errors"
          status="danger">{{ error }}</nb-alert>
</div>

<!--<p class="alert-title"><b i18n>Hooray!</b></p>-->
<div *ngIf="showMessages.success && messages?.length && !submitted">
  <nb-alert
          *ngFor="let message of messages"
          status="success">{{ message }}</nb-alert>
</div>

<div [formGroup]="loginForm" aria-labelledby="title">
  <div class="form-control-group">
    <label for="input-email" i18n>Email address:</label>
    <input  nbInput
            fullWidth
            id="input-email"
            formControlName="email"
            [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
            [attr.aria-invalid]="email.invalid && email.touched ? true : null"
            placeholder="Email address" i18n-placeholder>
    <ngx-validation-message
            label="Email address"
            i18n-label
            [showPattern]="email?.hasError('pattern') && email.touched"
      [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
  </div>

  <div class="form-control-group">
<!--    <span class="label-with-link">-->
<!--      <label class="label" for="input-password">Password:</label>-->
<!--      <a class="forgot-password caption-2F" routerLink="../request-password">Forgot Password?</a>-->
<!--    </span>-->
    <input  nbInput
            fullWidth
            id="input-password"
            formControlName="password"
            type="password"
            [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
            placeholder="Password" i18n-placeholder>
    <ngx-validation-message
            label="Password"
            i18n-label
            [showMinLength]="password?.hasError('minlength') && password.touched"
      [showMaxLength]="password?.hasError('maxlength') && password.touched"
      [showRequired]="password?.errors?.required && password.touched"
      [minLength]="minLength" [noWhitespace]="password.hasError('noWhitespace') && password.touched"
      [maxLength]="maxLength"></ngx-validation-message>
  </div>

<!--  <div class="form-control-group accept-group">-->
<!--    <nb-checkbox formControlName="rememberMe" name="rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>-->
<!--  </div>-->

  <button nbButton fullWidth status="primary" size="large"
   (click)="login()" [disabled]="submitted || !loginForm.valid"
    [class.btn-pulse]="submitted" i18n>
    Log In
  </button>
</div>

<!--<section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">-->
<!--  or enter with:-->
<!--  <div class="socials">-->
<!--    <ng-container *ngFor="let socialLink of socialLinks">-->
<!--      <a *ngIf="socialLink.link"-->
<!--         [routerLink]="socialLink.link"-->
<!--         [attr.target]="socialLink.target"-->
<!--         [attr.class]="socialLink.icon"-->
<!--         [class.with-icon]="socialLink.icon">-->
<!--        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>-->
<!--        <ng-template #title>{{ socialLink.title }}</ng-template>-->
<!--      </a>-->
<!--      <a *ngIf="socialLink.url"-->
<!--         [attr.href]="socialLink.url"-->
<!--         [attr.target]="socialLink.target"-->
<!--         [attr.class]="socialLink.icon"-->
<!--         [class.with-icon]="socialLink.icon">-->
<!--        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>-->
<!--        <ng-template #title>{{ socialLink.title }}</ng-template>-->
<!--      </a>-->
<!--    </ng-container>-->
<!--  </div>-->
<!--</section>-->

<!--<section class="another-action" aria-label="Register">-->
<!--  Don't have an account? <a class="text-link" routerLink="../register">Register</a>-->
<!--</section>-->
