import { AbstractControl, ValidationErrors } from "@angular/forms";

export function noWhitespaceValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value;
  if (!value || typeof value !== "string") {
    return null;
  }

  return value.trim() !== value ? { noWhitespace: true } : null;
}
