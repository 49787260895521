import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MeteostationsStore } from "./meteostations.store";
import { tap } from "rxjs/operators";
import { GlobalStore } from "../../global";
import { MeteostationsQuery } from "./meteostations.query";
import {
  MeteostationParameters,
  parameters,
} from "../../../shared/meteostations/meteostation-parameters";
import { MeteostationData } from "../../../shared/meteostations/models/meteostationData";
import { MeteoforecastData } from "../../../shared/meteostations/models/meteoforecastData";
import { Settings } from "../../../pages/meteostations/dashboard/header/dto/settings";
import { HttpService } from "../../../@core/backend/common/api/http.service";
import { ApiResponse } from "../../../@core/backend/common/api/apiResponse";
import { DataProcessService } from "../../../@core/utils/data-process.service";
import { WeeklyForecast } from "../../forecast-meteo/state/forecast-meteo.store";

@Injectable({ providedIn: "root" })
export class MeteostationsService {
  public allParameters: string[];
  public availableParameters = [];

  public constructor(
    private http: HttpService,
    private httpCl: HttpClient,
    private globalStore: GlobalStore,
    private store: MeteostationsStore,
    private meteostationsQuery: MeteostationsQuery,
    private dataProcessService: DataProcessService
  ) {}

  public async reload(settings, currentDevice) {
    this.updateParameters({ ...parameters });
    this.availableParameters = [];
    const meteoforecastData = await this.getForecastData(
      settings,
      currentDevice.serial_number
    );
    const meteostationData = await this.getMeteoStationsData(
      settings,
      currentDevice.serial_number
    );
    this.filterAllParameters();
    // response data should be set after parameters filtered
    this.store.update({
      meteostationsData: meteostationData,
      meteoforecastData: meteoforecastData,
    });
  }

  public async getMeteoStationsData(
    settings: Settings,
    serialNumber: string
  ): Promise<MeteostationData[]> {
    const resp: ApiResponse<MeteostationData[]> = await this.http
      .post(`influx\\meteostation`, {
        stationId: serialNumber,
        ...settings,
      })
      .toPromise();

    // vapor pressure deficit(vpd), wet bulb temperature and dewpoint temperature
    this.dataProcessService.addCalculatableData(resp.data);
    if (resp.data.length) {
      this.availableParameters.push(...Object.keys(resp.data[0]));
    }
    return resp.data;
  }

  // past 3 days
  public async getForecastData(
    settings: Settings,
    serialNumber: string
  ): Promise<MeteoforecastData[]> {
    const resp: ApiResponse<MeteoforecastData[]> = await this.http
      .post(`influx\\meteoforecast`, {
        stationId: serialNumber,
        start: settings.start,
        end: settings.end,
      })
      .pipe(tap((r) => this.dataProcessService.fixPollutants(r.data)))
      .toPromise();
    if (settings.interval === "30m") {
      resp.data = this.dataProcessService.fillForecast(resp.data);
    }
    this.availableParameters.push(...Object.keys(resp.data[0]));
    return resp.data;
  }

  public combineMeteoData(
    meteostationData: MeteostationData[],
    meteoforecastData: MeteoforecastData[]
  ) {
    let allMeteoData = [];
    if (meteostationData.length !== 0) {
      for (let i = 0; i < meteostationData.length; i++) {
        // finding corresponding forecast data for meteostation data using time
        const current = meteoforecastData.find(
          (d) => d.Time.slice(0, 16) === meteostationData[i].Time.slice(0, 16)
        );
        if (current) {
          allMeteoData.push({ ...current, ...meteostationData[i] });
        } else {
          allMeteoData.push({ ...meteostationData[i] });
        }
      }
    } else {
      allMeteoData = [...meteoforecastData];
    }

    if (this.globalStore.getValue().isShared) {
      const weeklyData = allMeteoData;
      allMeteoData = allMeteoData.slice(-72);
      this.store.update({ weeklyData, allMeteoData });
      return { allMeteoData, weeklyData };
    } else {
      this.store.update({ allMeteoData });
      return { allMeteoData };
    }
  }

  // 7 days from now
  public addForecast(forecast: WeeklyForecast[]) {
    let allMeteoData = [...this.meteostationsQuery.getValue().allMeteoData];
    const endTime = Date.parse(allMeteoData[allMeteoData.length - 1].Time);
    //  forecast starts when meteostationdata end
    const forecastBeginning = forecast.findIndex(
      (obj) => Date.parse(obj.Time) > endTime
    );
    // first three day of 7-day forecast
    forecast = forecast.slice(forecastBeginning, -96);
    // @ts-ignore
    allMeteoData = allMeteoData.concat(forecast);
    this.store.update({ allMeteoDataForGraphs: allMeteoData });
    return allMeteoData;
  }

  public getMapDeviceValueByParameter(parameter: string) {
    const resp = this.http.post("meteoDevicesParams", { param: parameter });
    return resp;
  }

  public getBoxplotData(stationID, param) {
    const resp = this.http.post("meteostation/graph/boxplot", {
      stationID,
      param,
    });
    return resp;
  }

  public updateParameters(parameters: MeteostationParameters) {
    this.allParameters = Object.values(parameters).map((param) => param.field);
    // this.store.update({ parameters });
    return parameters;
  }

  public filterAllParameters() {
    // const parameters = this.store.getValue().parameters;
    const params = { ...parameters };
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hasParamField = this.availableParameters.includes(
          params[key].field
        );
        if (!hasParamField && key !== "AQI") {
          delete params[key];
        }
      }
    }
    if (params.hasOwnProperty("gni_instant")) {
      params["gni_instant"].isCurrentReading =
        !params.hasOwnProperty("SolarRad");
    }
    this.store.update({ parameters: params });
    this.availableParameters = [];
  }

  public getHasSpecialParameters(): boolean {
    return !!Object.values(
      this.meteostationsQuery.getValue().parameters
    ).filter((p) => p.isSpecialReading && p.field !== "AQI").length;
  }
}
