import { Injectable } from "@angular/core";
import { MeteoforecastData } from "../../shared/meteostations/models/meteoforecastData";
import { MeteostationsQuery } from "../../store/meteostations/state";
import { WeeklyForecast } from "../../store/forecast-meteo/state/forecast-meteo.store";

@Injectable({
  providedIn: "root",
})
export class DataProcessService {
  constructor(private meteostationsQuery: MeteostationsQuery) {}

  public addCalculatableData(meteo) {
    meteo.forEach((reading) => {
      const vpd = this.calculateVPD(reading.AirT, reading.AirH);
      const dewPointTemperature = this.calculateDewPoint(
        reading.AirT,
        reading.AirH
      );
      const wetBulbTemperature = this.calculateWetBulb(
        reading.AirT,
        reading.AirH
      );
      Object.assign(reading, {
        vpd,
        WetBulbT: wetBulbTemperature,
        DewPointT: dewPointTemperature,
      });
    });
  }

  // function which calculates wet bulb temperature in Celsius from air temperature and relative humidity
  private calculateWetBulb(temperature, humidity) {
    const T = temperature;
    const RH = humidity;
    const Td = this.calculateDewPoint(T, RH);
    const Tw =
      T * Math.atan(0.151977 * Math.sqrt(RH + 8.313659)) +
      Math.atan(T + RH) -
      Math.atan(RH - 1.676331) +
      0.00391838 * Math.pow(RH, 1.5) * Math.atan(0.023101 * RH) -
      4.686035;
    return Math.round(Tw * 10) / 10;
  }

  // function which calculates dew point temperature in Celsius from air temperature and relative humidity
  private calculateDewPoint(temperature, humidity) {
    const T = temperature;
    const RH = humidity;
    const Td =
      (243.04 * (Math.log(RH / 100) + (17.625 * T) / (243.04 + T))) /
      (17.625 - Math.log(RH / 100) - (17.625 * T) / (243.04 + T));
    return Math.round(Td * 10) / 10;
  }

  //  function which calculates vapour pressure deficit from air temperature and relative humidity
  private calculateVPD(airT: number, airH: number) {
    const svp = 610.78 * Math.pow(Math.E, (airT / (airT + 238.3)) * 17.2694);
    const vpd = Math.round(svp * (1 - airH / 100));
    return Math.round((vpd / 1000) * 10) / 10;
  }

  public fixTemperature(temperature) {
    if (temperature < -6000) {
      return temperature + 6553.5;
    } else if (temperature > -6000 && temperature < -3000) {
      return temperature + 3276.75;
    }
    return temperature;
  }

  public fixPollutants(data) {
    data.forEach((d) => {
      if (d.hasOwnProperty("co")) {
        d.co = Math.round((24.45 / (28.01 * 1000)) * d.co * 10) / 10;
      }
      if (d.hasOwnProperty("no2")) {
        d.no2 = d.no2 / 1.88;
      }
      if (d.hasOwnProperty("so2")) {
        d.so2 = d.so2 / 2.62;
      }
    });
  }

  public fillForecast(forecast: MeteoforecastData[]) {
    const filledForecast = [];
    const params = Object.keys(forecast[0]).filter((p) => p !== "Time");
    for (let i = 0; i < forecast.length; i++) {
      if (filledForecast.length % 2 === 0) {
        filledForecast.push(forecast[i]);
      } else {
        const Time = new Date(
          (Date.parse(forecast[i - 1].Time) + Date.parse(forecast[i].Time)) / 2
        ).toISOString();
        const obj = { Time: Time };
        for (const param of params) {
          let curr = (forecast[i - 1][param] + forecast[i][param]) / 2;
          curr = param === "isDayLight" ? Math.round(curr) : curr;
          Object.assign(obj, { [param]: curr });
        }
        filledForecast.push(obj);
        i--;
      }
    }
    return filledForecast;
  }

  public fixSoilVWC(meteoforecast: WeeklyForecast[]) {
    let meteodata = this.meteostationsQuery.getValue().meteostationsData;
    let lastMeteoReading = [...meteodata][meteodata.length - 1];
    if (lastMeteoReading) {
      let lastSoilVWC = lastMeteoReading.SoilVWC;
      for (let i = 0; i < meteoforecast.length; i++) {
        const meteoTime = new Date(lastMeteoReading.Time).getTime();
        const forecastTime = new Date(meteoforecast[i].time).getTime();
        if (meteoforecast[i + 1] && meteoTime < forecastTime) {
          const diff =
            meteoforecast[i + 1]["soilmoisture_0to10cm"] -
            meteoforecast[i]["soilmoisture_0to10cm"];
          lastSoilVWC = lastSoilVWC + diff;
          meteoforecast[i]["soilmoisture_0to10cm"] = lastSoilVWC;
        }
      }
    }
  }
}
