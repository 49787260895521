import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "ngx-validation-message",
  styleUrls: ["./validation-message.component.scss"],
  template: `
    <div class="warning">
      <span class="caption status-danger" *ngIf="showMinLength" i18n>
        Min {{ label }} length is {{ minLength }} symbols
      </span>
      <span class="caption status-danger" *ngIf="showMaxLength" i18n>
        Max {{ label }} length is {{ maxLength }} symbols
      </span>
      <span class="caption status-danger" *ngIf="showPattern" i18n>
        Incorrect {{ label }}
      </span>
      <span class="caption status-danger" *ngIf="showRequired" i18n>
        {{ label }} is required</span
      >
      <span class="caption status-danger" *ngIf="showMin" i18n
        >Min value of {{ label }} is {{ min }}</span
      >
      <span class="caption status-danger" *ngIf="showMax" i18n
        >Max value of {{ label }} is {{ max }}</span
      >
      <span class="caption status-danger" *ngIf="noWhitespace" i18n
        >No whitespace at the start or end</span
      >
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgxValidationMessageComponent),
      multi: true,
    },
  ],
})
export class NgxValidationMessageComponent {
  @Input()
  label: string = "";

  @Input()
  showRequired?: boolean;

  @Input()
  min?: number;

  @Input()
  showMin?: boolean;

  @Input()
  max?: number;

  @Input()
  showMax: boolean;

  @Input()
  minLength?: number;

  @Input()
  showMinLength?: boolean;

  @Input()
  maxLength?: number;

  @Input()
  showMaxLength?: boolean;

  @Input()
  showPattern?: boolean;

  @Input()
  noWhitespace?: boolean;
}
