import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { MeteostationsState, MeteostationsStore } from "./meteostations.store";

@Injectable({ providedIn: "root" })
export class MeteostationsQuery extends Query<MeteostationsState> {
  constructor(protected store: MeteostationsStore) {
    super(store);
  }
}
