import { Injectable } from "@angular/core";
import { MeteostationsQuery } from "./meteostations.query";
import { StateHistoryPlugin } from "@datorama/akita";

@Injectable({
  providedIn: "root",
})
export class StateHistoryService {
  public currentStateIndex = -1;
  public stateHistory: StateHistoryPlugin;
  constructor(private meteostationsQuery: MeteostationsQuery) {}

  public setStateHistory() {
    if (!this.stateHistory) {
      this.stateHistory = new StateHistoryPlugin(this.meteostationsQuery, {
        maxAge: 50,
      });
    }
  }

  public getStateDetails(
    settings,
    currentDevice
  ): { index: number; time: string } {
    let stateIndex = this.findStateIndexByTime(
      this.stateHistory["history"].future,
      settings,
      currentDevice
    );
    let stateTime = "future";
    if (stateIndex === -1) {
      stateIndex = this.findStateIndexByTime(
        this.stateHistory["history"].past,
        settings,
        currentDevice
      );
      stateTime = "past";
    }
    this.currentStateIndex = stateIndex;
    return { index: stateIndex, time: stateTime };
  }

  public findStateIndexByTime(history, settings, currentDevice) {
    const reversedHistory = history.slice().reverse();
    for (let i = 0; i < reversedHistory.length; i++) {
      const currentState = reversedHistory[i];
      if (
        currentState.currentMeteostation &&
        currentState.currentMeteostation.serial_number ===
          currentDevice.serial_number
      ) {
        if (
          currentState.headerSettings &&
          settings.start === currentState.headerSettings.start &&
          settings.end === currentState.headerSettings.end &&
          settings.refresh === currentState.headerSettings.refresh
        ) {
          return i;
        }
      }
    }
    return -1;
  }

  public changeState(stateTime: string, stateIndex: number) {
    if (stateTime === "past") {
      const jumpTo = this.stateHistory["history"].past.length - stateIndex - 1;
      this.stateHistory.jumpToPast(jumpTo);
    } else if (stateTime === "future") {
      const jumpTo =
        this.stateHistory["history"].future.length - stateIndex - 1;
      this.stateHistory.jumpToFuture(jumpTo);
    }
  }
}
